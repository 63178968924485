import { createBystander, sendSMSAPI } from "../api/backendApi";
import { normalizePhone } from "../helper/helper";

export const dispatcherAuthManager = {
    isPhoneNumberConsumed: false,
    phone: null,
    bystanderToken: null,
    backendSessionId: null,

    /**
     * create a bystander user
     * @param {string} phoneNumber
     */
    createBystanderToken: async phoneNumber => {
        const result = await createBystander(phoneNumber);
        if (result) {
            dispatcherAuthManager.bystanderToken = result.token;
        }
        return dispatcherAuthManager.bystanderToken;
    },

    /**
     * send a sms
     * @param {boolean} dryRun
     */
    sendSMS: async (dryRun = false, isResendSMS = false, callerId) => {
        const data = {
            token: dispatcherAuthManager.bystanderToken,
            bystanderId: callerId,
            phoneNumber: normalizePhone(dispatcherAuthManager.phone),
            dryRun: dryRun,
            isResendSMS: isResendSMS,
        };

        return await sendSMSAPI(data);
    },

    /**
     * init a new session
     * @param {string} phone
     */
    initSession: phone => {
        dispatcherAuthManager.phone = phone;
        dispatcherAuthManager.backendSessionId = Math.random().toString().substring(2);
    },
};
