import React from 'react';
import PropTypes from 'prop-types';

import { dispatcherWebRTCManager } from '../../webrtc/webrtcManagers/DispatcherWebRTCManager';
import { oauthStore } from '../../store/OAuthStore';

import LogoutButton from './LogoutButton';
import Loading from '../Globals/Loading';
import SystemMessages from './SystemMessages';
import SystemFooter from '../Globals/SystemFooter';
import LanguageSelect from '../Globals/LanguageSelect';

import {
    getTitle,
    getLogo,
    isValidPhone,
    replaceText,
    setDispatcherLanguage,
    isOnStartPage,
    getPasswordExpirationTime,
    normalizePhone,
    createKpiLog,
} from '../../helper/helper';
import { errorLog } from '../../helper/logging';
import { API_RTC_RESOURCE_TIMEOUT, DEBUG, PLACEHOLDER_COUNTY_CODE, POLLING_INTERVAL, POLLING_RETRY } from '../../config';

import { connect } from 'react-redux';
import {
    getPhoneNumber,
    phoneNumberConsumed,
    getApplicationFeaturesDispatcher,
    getContactsDispatcher,
    getCurrentDispatchCenterConfig,
    getImage,
} from '../../api/backendApi';

import './StartDashboard.scss';
import { addLogDispatch } from '../../redux/actions/logs';
import { enableFeaturesDispatch } from '../../redux/actions/features';

import SessionDownload from './SessionDownload';
import { setPhonenumbersDispatch } from '../../redux/actions/phonenumbers';
import { dispatchAddWhitelabelLogo } from '../../redux/actions/session';
import { PhoneCountry } from '../Globals/PhoneCountry';
import { Phonebook } from '../Dispatcher/Phonebook';
import { hideAndRemoveNotificationsDispatch } from '../../redux/actions/notifications';
import DisclaimerPanel from '../Globals/DisclaimerPanel';
import { webRtcLoader } from '../../helper/webRtcLoader';
import { dispatchActivatePhotoAutoDownload, dispatchDeactivatePhotoAutoDownload, dispatchDeactivateWebRtc } from '../../redux/actions/application';
import { getPasswordExpiration } from '../../api/passwordApi';
import { dispatcherAuthManager } from '../../store/DispatcherAuthManager';

const showSMSCheckbox = process.env.REACT_APP_SHOW_SMS_CHECKBOX === 'true';
const sendSMS = process.env.REACT_APP_SEND_SMS === 'true';

/**
 * StartDashboard
 * Input the phonenumber to connect a caller.
 * Shows any system messages from the backend.
 * Download the session file after a session.
 *
 * Connected backend apis:
 * - bystander user creation
 * - sms sending to provided phone number
 * - polling of phone number to consume
 *
 * @component LogoutButton - logs the user out
 * @component Loading - loading spinner
 * @component SystemMessages - shows the messages from admin ui
 * @component SystemFooter - shows health, version and helpdesk
 */

class StartDashboard extends React.Component {
    _pollInterval = 0;
    _pollTimeout = 0;
    _rtcResourceErrorTimer = null;

    constructor(props) {
        super(props);
        this.state = {
            phone: '',
            sendSMS: sendSMS,
            loading: false,
            passwordWarningDisplayed: false,
        };
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ loading: true });
        // clear web rtc disconnection timeout as this is no longer needed on start
        clearTimeout(dispatcherWebRTCManager.webRtcDisconnectionTimeout);
        // remove pre-existing script from document body if already loaded
        if (window && window.apiRTC) {
            delete window.apiRTC;
        }
        dispatcherAuthManager.phone = this.state.phone;
        // polling for phone number consumed
        this.startPolling();
        this.startWebRtcResourceTimer();
        webRtcLoader();
    };

    startSessionInitializationProcess = async (phone = this.state.phone) => {
        let bystanderToken;
        try {
            // create caller (bystander) token in backend
            if (!dispatcherAuthManager.isPhoneNumberConsumed) {
                bystanderToken = await dispatcherAuthManager.createBystanderToken(phone);
            } else {
                bystanderToken = dispatcherAuthManager.bystanderToken;
            }

            if (!bystanderToken) {
                this.setState({
                    loading: false,
                });
                return;
            }

            // register web rtc dispatcher user agent and start web rtc session
            const sessionId = await dispatcherWebRTCManager.registerUserAgentAndStartSession({
                username: oauthStore.userName,
                token: oauthStore.authToken,
            });

            // use web rtc sessionId as callerId
            dispatcherWebRTCManager.callerId = sessionId;

            // send sms to caller
            const sms = await dispatcherAuthManager.sendSMS(!this.state.sendSMS, false, dispatcherWebRTCManager.callerId).catch(e => {
                this.setState({
                    loading: false,
                });
                dispatchDeactivateWebRtc();
                dispatcherWebRTCManager.closeSession();
            });

            if (sms.url.indexOf('null') !== -1 || sms.url.indexOf('undefined') !== -1) {
                this.setState({
                    loading: false,
                });

                return;
            }
            if (this._pollInterval) {
                this.clearPolling();
                if (DEBUG) addLogDispatch(['polling api ended']);
            }

            // log web rtc platform in use
            if (dispatcherWebRTCManager.isPrimaryPlatformUsed) {
                createKpiLog('infoWebRTCPrimaryPlatform');
            } else if (!dispatcherWebRTCManager.isPrimaryPlatformUsed) {
                createKpiLog('infoWebRTCSecondaryPlatform');
            }

            // clear all notifications
            hideAndRemoveNotificationsDispatch('error');
            hideAndRemoveNotificationsDispatch('info');
            hideAndRemoveNotificationsDispatch('warning');

            // proceed to dispatcher dashboard
            this.props.history.push('/disptchr/' + dispatcherWebRTCManager.callerId + '/' + dispatcherAuthManager.bystanderToken);
        } catch (e) {
            errorLog({
                message: 'error while handling phone number',
                error: e,
                eventId: 'HANDLE_PHONE_NUMBER',
            });
        }
    };

    async componentDidMount() {
        enableFeaturesDispatch(await getApplicationFeaturesDispatcher());

        const whitelabelLogo = await getImage({ type: 'base64', endpoint: process.env.REACT_APP_IMAGE_DISPLAY_ENDPOINT });
        if (whitelabelLogo) {
            dispatchAddWhitelabelLogo({ logo: whitelabelLogo });
        }
        setDispatcherLanguage();

        const dispatchCenterConfig = await getCurrentDispatchCenterConfig();

        if (dispatchCenterConfig) {
            if (dispatchCenterConfig.preventAutoDownload && dispatchCenterConfig.preventAutoDownload === true) {
                dispatchDeactivatePhotoAutoDownload();
            } else {
                dispatchActivatePhotoAutoDownload();
            }
        }
        this.startPolling();
    }

    async componentDidUpdate(prevProps) {
        if (this.props.webRtcIsActive) {
            this.startPolling();
            this.startSessionInitializationProcess(dispatcherAuthManager.phone);
            clearTimeout(this._rtcResourceErrorTimer);
        }

        if (dispatcherWebRTCManager && dispatcherWebRTCManager.connectedSession && dispatcherWebRTCManager.connectedSession !== null) {
            return;
        }

        if (prevProps.phonebookFeature !== this.props.phonebookFeature) {
            setPhonenumbersDispatch(await getContactsDispatcher());
        }

        if (Object.keys(this.props.texts).length !== 0 && isOnStartPage() && !this.props.passwordChangeSent && !this.state.passwordWarningDisplayed) {
            const passwordExpiration = await getPasswordExpiration();
            getPasswordExpirationTime(passwordExpiration);
            this.setState({
                passwordWarningDisplayed: true,
            });
        } else {
            hideAndRemoveNotificationsDispatch('pending');
        }
    }

    componentWillUnmount() {
        clearTimeout(this._pollTimeout);
        this.clearPolling();
    }

    validateForm() {
        return isValidPhone(this.state.phone);
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value,
        });
    };

    handleChangeCheckBox = event => {
        this.setState({
            [event.target.id]: event.target.checked,
        });
    };

    pollAPI = () => {
        if (DEBUG) addLogDispatch(['polling api started']);
        this._pollInterval = setInterval(async () => {
            try {
                const response = await getPhoneNumber();
                if (response && response.phoneNumber && response.bystanderToken) {
                    dispatcherAuthManager.bystanderToken = response.bystanderToken;
                    if (DEBUG) addLogDispatch(['polling api ended']);
                    this.clearPolling();
                    // remove pre-existing script from document body if already loaded
                    if (window && window.apiRTC) {
                        delete window.apiRTC;
                    }
                    webRtcLoader();
                    const formattedPhone = normalizePhone(response.phoneNumber);
                    await phoneNumberConsumed(formattedPhone);
                }
            } catch (e) {
                this.clearPolling();
                this._pollTimeout = setTimeout(this.pollAPI, POLLING_RETRY);
            }
        }, POLLING_INTERVAL);
    };

    // show error page if dispatcher dashboard not displayed in 30 seconds
    startWebRtcResourceTimer = () => {
        this._rtcResourceErrorTimer = setTimeout(() => {
            if (DEBUG) console.log('timeout while loading apiRTC script');
            this.resourceNotAvailable({ stack: 'TIMEOUT LOADING' });
            clearTimeout(this._rtcResourceErrorTimer);
        }, API_RTC_RESOURCE_TIMEOUT);
    };

    resourceNotAvailable = e => {
        errorLog({
            message: 'apiRTC not a available - dispatcher',
            error: e,
            eventId: 'APIRTC_SCRIPT_LOADING_DISPATCHER',
        });
        window.setTimeout(() => {
            window.location.href = '/error?error=apiRTC script currently not available';
        }, 100);
    };

    startPolling = () => {
        this.clearPolling();
        this.pollAPI();
    };

    clearPolling = () => {
        clearInterval(this._pollInterval);
    };

    selectAddress = phone => {
        this.setState({ phone });
    };

    render() {
        const isAllowed = this.props.usageDisclaimerStateDispatcher === 'accepted' || this.props.usageDisclaimerStateDispatcher === 'none';

        return (
            <div className="startDashboard">
                <div className="navbar">
                    <div>
                        <div className="logo">
                            <img src={getLogo()} alt="logo" />
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: getTitle(),
                                }}></span>
                        </div>
                    </div>
                    <div>
                        <LanguageSelect />
                        <LogoutButton beforeLogout={this.clearPolling} />
                    </div>
                </div>

                <div className="Login">
                    {this.props.sessionDownloadFeature && <SessionDownload />}
                    <DisclaimerPanel user="dispatcher" />

                    {isAllowed ? (
                        <form onSubmit={this.handleSubmit}>
                            <div className="form__group">
                                <label className="flex">
                                    <span>{replaceText(this.props.texts, 'new.phone')}</span>
                                    <PhoneCountry phone={this.state.phone} />
                                </label>
                                <input
                                    id="phone"
                                    autoFocus
                                    type="phone"
                                    placeholder={`+${PLACEHOLDER_COUNTY_CODE}... ${replaceText(
                                        this.props.texts,
                                        'new.phone.seperator'
                                    )} 00${PLACEHOLDER_COUNTY_CODE}...`}
                                    value={this.state.phone}
                                    onChange={this.handleChange}
                                />
                            </div>
                            {showSMSCheckbox ? (
                                <div className="form__group">
                                    <label>
                                        <input autoFocus type="checkbox" id="sendSMS" value={this.state.sendSMS} onChange={this.handleChangeCheckBox} />
                                        {replaceText(this.props.texts, 'new.sendsms')}
                                    </label>
                                </div>
                            ) : (
                                ''
                            )}
                            {this.state.loading && <Loading text={replaceText(this.props.texts, 'sms.sending')} />}
                            {!this.state.loading && (
                                <button id="start" className="btn btn--primary btn--full" disabled={!this.validateForm()} type="submit">
                                    {replaceText(this.props.texts, 'new.button')}
                                </button>
                            )}
                            {this.props.phonebookFeature && <Phonebook clickHandler={this.selectAddress} showName />}
                        </form>
                    ) : (
                        <Loading text={replaceText(this.props.texts, 'application.loading')} />
                    )}
                </div>
                {isAllowed && <SystemMessages />}
                <SystemFooter />
            </div>
        );
    }
}

// PropTypes for this Component
StartDashboard.propTypes = {
    match: PropTypes.any,
    history: PropTypes.any,
    texts: PropTypes.any,
    sessionDownloadFeature: PropTypes.bool,
    phonebookFeature: PropTypes.bool,
    dispatcherAudioStream: PropTypes.object,
    usageDisclaimerStateDispatcher: PropTypes.string,
    webRtcIsActive: PropTypes.bool,
    passwordChangeSent: PropTypes.bool,
};

const mapStateToProps = state => {
    return {
        usageDisclaimerStateDispatcher: state.disclaimers.usageDisclaimerStateDispatcher,
        sessionDownloadFeature: state.features.sessionDownloadFeature,
        texts: state.texts.texts,
        phonebookFeature: state.features.phonebookFeature,
        dispatcherAudioStream: state.streamSlice.dispatcherAudioStream,
        webRtcIsActive: state.application.webRtcIsActive,
        passwordChangeSent: state.application.passwordChangeSent,
    };
};

export default connect(mapStateToProps)(StartDashboard);
