import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';

import ConnectionOverlay from '../Globals/ConnectionOverlay';
import NoVideo from '../Icons/NoVideo';
import './VideoContainer.scss';
import './VideoContainerDispatcher.scss';
import { POINTER_BLINKING_DURATION } from '../../config';
import {
    deactivatePointerDispatcherDispatch,
    deactivateSnapshotDispatch,
    deactivateVideoDispatcherDispatch,
    dispatchUnsetLiveVideoIsLoading,
    pauseSnapshotDispatch,
    unpauseSnapshotDispatch,
} from '../../redux/actions/application';
import { PaintCanvas } from '../Canvas/PaintCanvas';
import { PaintToolbar } from '../Canvas/PaintToolbar';
import SnapshotButtons from '../Dispatcher/SnapshotButtons';
import Loading from '../Icons/LoadingSpinner';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { usePrevious } from '../../helper/hooks';
import { sendPointerPosition } from '../../webrtc/outgoingMessages/outgoingMessagesDispatcher';
import { Stream } from '../../types/stream.types';
import { useResizeDetector } from 'react-resize-detector';

/**
 * VideoContainerDispatcher
 * Shows the video if activated. Stream data is transmitted via an established webRTC connection.
 * Contains the pointer functionality.
 *
 * @component Connection Overlay - shows waiting for connection until webRTC connection is established
 */

export const VideoContainerDispatcher = () => {
    const videoIsActive = useSelector((state: RootState) => state.application.videoIsActive);
    const pointerIsActive = useSelector((state: RootState) => state.application.pointerIsActive);
    const hasVideoStream = useSelector((state: RootState) => state.application.hasVideoStream);
    const pointerFeature = useSelector((state: RootState) => state.features.pointerFeature);
    const drawFeature = useSelector((state: RootState) => state.features.drawFeature);
    const drawIsActive = useSelector((state: RootState) => state.application.drawIsActive);
    const isPaintingAllowed = useSelector((state: RootState) => state.paint.isPaintingAllowed);
    const videoIsLoading = useSelector((state: RootState) => state.application.videoIsLoading);
    const callerStream = useSelector((state: RootState) => state.streamSlice.callerStream);
    const externalStreamIsActive = useSelector((state: RootState) => state.application.externalStreamIsActive);
    const snapshotIsActive = useSelector((state: RootState) => state.application.snapshotIsActive);

    const [pointerChanged, setPointerChanged] = useState(false);
    const [manualWidth, setManualWidth] = useState(null);
    const [manualHeight, setManualHeight] = useState(null);

    const prevCallerStream = usePrevious(callerStream);
    const prevExternalStreamIsActive = usePrevious(externalStreamIsActive);

    const classes = clsx('videoContainer', {
        'videoContainer--active': videoIsActive,
        'videoContainer--pointer': pointerIsActive,
        'videoContainer--pointerVisible': pointerChanged,
        'videoContainer--isHidden': externalStreamIsActive,
    });

    const changeDimensions = (width, height) => {
        if (!ref.current) {
            // If the ref is null, do nothing
            return;
        }
        
        const videoElement = ref.current.querySelector('video');

        if (!isPaintingAllowed) {
            // only update state if painting is not allowed
            setManualWidth(width);
            setManualHeight(height);
            // reset video with
            if (videoElement) {
                videoElement.style.maxWidth = null;
                videoElement.style.maxHeight = null;
            }
        } else {
            // set fixed video width
            if (videoElement) {
                videoElement.style.maxWidth = manualWidth + 'px';
                videoElement.style.minWidth = manualWidth + 'px';
                videoElement.style.maxHeight = manualHeight + 'px';
                videoElement.style.minHeight = manualHeight + 'px';
            }
        }
    };

    const { width, height, ref } = useResizeDetector({
        handleWidth: true,
        handleHeight: true,
        onResize: () => changeDimensions,
    });

    const toggleControls = useCallback(() => {
        if (ref && ref.current) {
            const videoElement = ref.current.querySelector('video');
            if (videoElement) {
                videoElement.controls = !pointerIsActive;
            }
        }
    }, [pointerIsActive, ref]);

    const addPointer = useCallback(
        e => {
            let _pointerTimeout = null;

            if (ref && ref.current) {
                const videoElement = ref.current.querySelector('.videoElement') as HTMLVideoElement | null;
                const pointerElement = ref.current.querySelector('.pointerElement') as HTMLDivElement | null;
                if (videoElement) {
                    const position = {
                        xPercent: (e.layerX / e.currentTarget.getBoundingClientRect().width) * 100,
                        yPercent: (e.layerY / e.currentTarget.getBoundingClientRect().height) * 100,
                    };

                    if (pointerElement) {
                        pointerElement.style.left = `${position.xPercent}%`;
                        pointerElement.style.top = `${position.yPercent}%`;

                        setPointerChanged(true);
                        videoElement.pause();
                        pauseSnapshotDispatch();
                        clearTimeout(_pointerTimeout);
                        _pointerTimeout = setTimeout(() => {
                            videoElement.play();
                            unpauseSnapshotDispatch();
                            setPointerChanged(false);
                        }, POINTER_BLINKING_DURATION);
                    }

                    sendPointerPosition(position);
                }
            }
        },
        [ref]
    );

    const addPointerListener = useCallback(() => {
        if (ref && ref.current) {
            const videoElement = ref.current?.querySelector('video');
            if (videoElement) {
                if (pointerIsActive) {
                    videoElement.addEventListener('click', addPointer);
                } else {
                    videoElement.removeEventListener('click', addPointer);
                }
            }
        }
    }, [addPointer, pointerIsActive, ref]);

    useEffect(() => {
        if (pointerFeature && hasVideoStream) {
            toggleControls();
            addPointerListener();
        }
    }, [pointerFeature, hasVideoStream, toggleControls, addPointerListener]);

    useEffect(() => {
        if (videoIsActive && ref.current) {
            if (!isPaintingAllowed) {
                const videoElement = ref.current.querySelector('video');
                if (videoElement) {
                    videoElement.style.maxWidth = null;
                    videoElement.style.minWidth = null;
                    videoElement.style.maxHeight = null;
                    videoElement.style.minHeight = null;
                }
            }
        }
    }, [videoIsActive, isPaintingAllowed, ref]);

    useEffect(() => {
        if (externalStreamIsActive && externalStreamIsActive !== prevExternalStreamIsActive && videoIsActive) {
            deactivateVideoDispatcherDispatch();
            if (snapshotIsActive) deactivateSnapshotDispatch();
            if (pointerIsActive) deactivatePointerDispatcherDispatch();
        }
    }, [externalStreamIsActive, prevExternalStreamIsActive, videoIsActive, pointerIsActive, snapshotIsActive]);

    useEffect(() => {
        if (callerStream && prevCallerStream) {
            if (callerStream.streamId !== (prevCallerStream as Stream).streamId) {
                dispatchUnsetLiveVideoIsLoading();
            }
        }
    }, [callerStream, prevCallerStream]);

    return (
        <>
            <div className={classes}>
                <ConnectionOverlay force={true} />
                {videoIsActive ? (
                    ''
                ) : (
                    <div className="videoContainer__placeholder">
                        <NoVideo />
                    </div>
                )}

                <div className="videoContainer__inner-wrapper">
                    <div ref={ref} id="videoContainer__inner"></div>
                    {videoIsLoading && callerStream ? <Loading /> : ''}

                    {drawFeature && drawIsActive && (
                        <>
                            <PaintCanvas currentUser="dispatcher" width={width} height={height} />
                            <PaintToolbar />
                        </>
                    )}
                </div>
            </div>
            {!externalStreamIsActive ? <SnapshotButtons /> : ''}
        </>
    );
};
