import { postNewRecording } from '../../api/backendApi';
import { DEBUG, DISPLAY_ONLY_IN_SESSION, MAX_ALLOWED_CONFERENCE_USERS } from '../../config';
import { getComplexObject } from '../../helper/complexObjectStorage';
import { createKpiLog } from '../../helper/helper';
import { fetchVideoAndThumbnailAndDispatch, getURLParams } from '../../helper/rtcFlowHandling';
import { addCallerVideoStreamInDiv } from '../../helper/streamHandling';
import {
    addCallerAudioStream,
    addCallerStream,
    addConferenceUserStream,
    removeCallerAudioStream,
    removeConferenceUserStream,
} from '../../redux/actionCreators/actionCreators';
import { deactivateAudioStreamDispatcherDispatch } from '../../redux/actions/application';
import { dispatchAddStreamInfo, dispatchRemoveStreamInfo } from '../../redux/actions/conferencing';
import { connectionLostDispatch } from '../../redux/actions/connection';
import { dispatchAddInvitedUser, dispatchRemoveInvitedUser, dispatchToggleAudioMuted } from '../../redux/actions/invitedUsers';
import { addLogDispatch } from '../../redux/actions/logs';
import { addNotificationAndShowDispatch } from '../../redux/actions/notifications';
import { dispatchAddSessionRecording } from '../../redux/actions/session';
import {
    addSessionDevice,
    deactivateSessionHandover,
    deactivateSessionHijacking,
    replaceCallerDevice,
    unsetIsHeadMountedDisplayDevice,
    updateActiveDeviceId,
} from '../../redux/slices/sessionHandlingSlice';
import store from '../../redux/store';
import reduxStore from '../../redux/store';
import { serializeContact } from '../../redux/utils/serializeContact';
import { serializeStreamInfo } from '../../redux/utils/serializeStreamInfo';
import { sixDigitRegex } from '../../regex';
import { WebRtcMessageDispatcher } from '../../types';
import {
    sendApplicationData,
    sendDispatcherLeftToCaller,
    sendDispatcherLeftToUser,
    sendHandoverSessionToCallerDevice,
    sendJoinRequestGrantedToUser,
    sendSessionData,
    sendSessionHandlingData,
} from '../outgoingMessages/outgoingMessagesDispatcher';
import { dispatcherWebRTCManager } from '../webrtcManagers/DispatcherWebRTCManager';

// handlers for the dispatcher session and conversation
export const loadEventListenersDispatcher = () => {
    // both the caller and invited users joining the session
    dispatcherWebRTCManager.connectedSession.on('contactListUpdate', handleUserJoined);

    dispatcherWebRTCManager.connectedConversation
        .on('streamAdded', handleStreamAdded)
        .on('streamRemoved', handleStreamRemoved)
        .on('streamListChanged', handleStreamChanged)
        .on('contactJoined', handleContactJoinedDispatcher)
        .on('contactLeft', handleContactLeftDispatcher)
        .on('recordingStarted', handleStreamRecordingStarted)
        .on('recordingStopped', handleStreamRecordingStopped)
        .on('recordingAvailable', handleStreamRecordingIsAvailable);
};

export const unloadEventListenersDispatcher = () => {
    dispatcherWebRTCManager.connectedSession.removeListener('contactListUpdate', handleUserJoined);

    dispatcherWebRTCManager.connectedConversation
        .removeListener('streamAdded', handleStreamAdded)
        .removeListener('streamRemoved', handleStreamRemoved)
        .removeListener('streamListChanged', handleStreamChanged)
        .removeListener('contactJoined', handleContactJoinedDispatcher)
        .removeListener('contactLeft', handleContactLeftDispatcher)
        .removeListener('recordingStarted', handleStreamRecordingStarted)
        .removeListener('recordingStopped', handleStreamRecordingStopped)
        .removeListener('recordingAvailable', handleStreamRecordingIsAvailable);
};

const handleUserJoined = updatedContact => {
    // check if updatedContact contains any user data
    if (updatedContact.userDataChanged.length > 0) {
        const { callerId } = getURLParams();

        const joinedUser = updatedContact.userDataChanged[0];
        const joinedUserSerialized = serializeContact(updatedContact.userDataChanged[0]);

        if (DEBUG) addLogDispatch(['user joined session:', joinedUserSerialized]);

        /**
         * INVITED USERS ONLY
         **/
        if (reduxStore.getState().features.inviteFeature && joinedUserSerialized.contact.userData.username !== callerId) {
            if (reduxStore.getState().invitedUsers.length > 0) {
                reduxStore.getState().invitedUsers.map(currentUser => {
                    // check if user exists via user id to prevent adding a duplicate on name change
                    const userExists = currentUser.userData.id === joinedUserSerialized.contact.userData.id;
                    if (userExists) {
                        dispatchRemoveInvitedUser(currentUser);
                    }
                    return null;
                });

                if (
                    joinedUserSerialized.contact.userData.username.indexOf('guest-') === -1 &&
                    !sixDigitRegex.test(joinedUserSerialized.contact.userData.username)
                ) {
                    dispatchAddInvitedUser(joinedUserSerialized.contact);
                    const responseMessage = {
                        data: WebRtcMessageDispatcher.JOIN_REQUEST_IS_GRANTED,
                    };

                    dispatcherWebRTCManager.sendMessageToUser(responseMessage, joinedUser);
                    sendSessionData();
                    sendApplicationData();
                    sendSessionHandlingData();
                }
            } else {
                // sixDigitRegex prevents users with a webrtc randomly generated 6 digit name from being added
                if (
                    joinedUserSerialized.contact.userData.username.indexOf('guest-') === -1 &&
                    !sixDigitRegex.test(joinedUserSerialized.contact.userData.username)
                ) {
                    dispatchAddInvitedUser(joinedUserSerialized.contact);
                    const responseMessage = {
                        data: WebRtcMessageDispatcher.JOIN_REQUEST_IS_GRANTED,
                    };

                    dispatcherWebRTCManager.sendMessageToUser(responseMessage, joinedUserSerialized.contact);
                    sendSessionData();
                    sendApplicationData();
                    sendSessionHandlingData();
                }
            }
        }

        /**
         * CALLER ONLY
         **/
        // add device with callerId if none yet exist
        if (reduxStore.getState().sessionHandlingSlice.devices.length === 0 && joinedUserSerialized.contact.userData.username === callerId) {
            store.dispatch(addSessionDevice(joinedUserSerialized));
            store.dispatch(updateActiveDeviceId(joinedUserSerialized.contact.userData.id));
            sendJoinRequestGrantedToUser(joinedUser);
            dispatcherWebRTCManager.establishConnectivityWithNewCaller(joinedUser);
            return;
        }

        // device with callerId attempts to join
        if (joinedUserSerialized.contact.userData.username === callerId && reduxStore.getState().sessionHandlingSlice.devices.length !== 0) {
            // check if device has previously joined
            const deviceIdExists = reduxStore
                .getState()
                .sessionHandlingSlice.devices.some(device => device.contact.userData.id === joinedUserSerialized.contact.userData.id);

            // while session handover is activated
            if (reduxStore.getState().sessionHandlingSlice.sessionHandoverIsActive) {
                // reset hmd
                if (reduxStore.getState().sessionHandlingSlice.isHeadMountedDisplayDevice) {
                    store.dispatch(unsetIsHeadMountedDisplayDevice());
                }
                // send session transfer success message to current caller device
                sendHandoverSessionToCallerDevice();

                // then update active device id
                store.dispatch(updateActiveDeviceId(joinedUserSerialized.contact.userData.id));

                if (!deviceIdExists) {
                    // dispatchAddSessionDevice(joinedUserSerialized);
                    store.dispatch(addSessionDevice(joinedUserSerialized));
                }

                sendJoinRequestGrantedToUser(joinedUser);

                // we need a timeout here, since the messages can be sent in the wrong order
                // and we need to wait for the correct detection of the new caller device
                setTimeout(() => {
                    store.dispatch(deactivateSessionHandover());
                }, 500)
                dispatcherWebRTCManager.establishConnectivityWithNewCaller(joinedUser);
                createKpiLog('sessionHandoverSuccessful', '', { 0: joinedUserSerialized.contact.userData.id });
                dispatcherWebRTCManager.toggleOffAllFeatures();
                addNotificationAndShowDispatch('session.handover.success', 'info', DISPLAY_ONLY_IN_SESSION);
            } else {
                // while session handover is deactivated
                if (deviceIdExists) {
                    // reset hmd
                    if (reduxStore.getState().sessionHandlingSlice.isHeadMountedDisplayDevice) {
                        store.dispatch(unsetIsHeadMountedDisplayDevice());
                    }
                    // hand over the session to rejoining device
                    if (joinedUserSerialized.contact.userData.id !== reduxStore.getState().sessionHandlingSlice.activeDeviceId) {
                        // send disconnect message to current caller device
                        sendHandoverSessionToCallerDevice();

                        store.dispatch(updateActiveDeviceId(joinedUserSerialized.contact.userData.id));
                        sendJoinRequestGrantedToUser(joinedUser);
                        dispatcherWebRTCManager.establishConnectivityWithNewCaller(joinedUser);
                        createKpiLog('callerDeviceChanged', '', { 0: joinedUserSerialized.contact.userData.id });
                        addNotificationAndShowDispatch('session.device.changed', 'info', DISPLAY_ONLY_IN_SESSION);
                        dispatcherWebRTCManager.toggleOffAllFeatures();
                    }
                } else {
                    // handle new caller device attempting to join
                    if (!reduxStore.getState().connection.isConnected || reduxStore.getState().sessionHandlingSlice.sessionHijackingIsActive) {
                        // reset hmd
                        if (reduxStore.getState().sessionHandlingSlice.isHeadMountedDisplayDevice) {
                            store.dispatch(unsetIsHeadMountedDisplayDevice());
                        }
                        // if the connection is lost or session hijacking is active, allow new device to hijack the session
                        const currentCallerDevice = reduxStore
                            .getState()
                            .sessionHandlingSlice.devices.find(
                                device => device.contact.userData.id === reduxStore.getState().sessionHandlingSlice.activeDeviceId
                            );

                        // send disconnect message to current caller device and remove from device list
                        sendDispatcherLeftToCaller();

                        // replace current caller device with joining user device
                        store.dispatch(replaceCallerDevice({ currentDevice: currentCallerDevice, newDevice: joinedUserSerialized }));
                        store.dispatch(updateActiveDeviceId(joinedUserSerialized.contact.userData.id));
                        sendJoinRequestGrantedToUser(joinedUser);
                        dispatcherWebRTCManager.establishConnectivityWithNewCaller(joinedUser);
                        dispatcherWebRTCManager.toggleOffAllFeatures();
                    } else {
                        // send disconnect message to new device attempting to connect
                        sendDispatcherLeftToUser(joinedUser);
                    }
                }
            }
            store.dispatch(deactivateSessionHijacking());
        }
    }
};

// On incoming stream event, place in corresponding div container, add stream object to redux store, and subscribe to stream

const handleStreamAdded = stream => {
    const { callerId } = getURLParams();
    if (stream.contact.userData.username === callerId) {
        // incoming caller stream
        if (stream.type === 'audio') {
            store.dispatch(addCallerAudioStream(stream));
            handleIncomingAudio();
        }
        if (stream.type === 'video') {
            store.dispatch(addCallerStream(stream));
            addCallerVideoStreamInDiv(stream, true);
        }
    } else {
        // incoming conference user stream
        const additionalStates = {
            0: stream.contact.userData.username,
        };

        createKpiLog('infoConferenceUser', 'joined', additionalStates);

        store.dispatch(addConferenceUserStream(stream));
        handleIncomingAudio();
    }
};

const handleStreamRemoved = stream => {
    const { callerId } = getURLParams();

    if (stream.contact.userData.username === callerId) {
        // caller stream
        if (stream.type === 'audio') {
            store.dispatch(removeCallerAudioStream(stream));
            handleRemovalOfIncomingStream(stream);
        }
    } else {
        const additionalStates = {
            0: stream.contact.userData.username,
        };

        createKpiLog('infoConferenceUser', 'left', additionalStates);

        store.dispatch(removeConferenceUserStream(stream));
        handleRemovalOfIncomingStream(stream);
    }
};

const handleStreamChanged = streamInfo => {
    if (streamInfo.isRemote === true) {
        if (streamInfo.listEventType === 'added') {
            dispatchAddStreamInfo(serializeStreamInfo(streamInfo));
            dispatcherWebRTCManager.connectedConversation
                .subscribeToMedia(streamInfo.streamId)
                .then(stream => {
                    console.log('subscribeToMedia succeeded');

                    dispatchToggleAudioMuted({ userId: streamInfo.contact.userData.id, isAudioMuted: streamInfo.isAudioMuted });
                })
                .catch(err => {
                    console.error('subscribeToMedia error', err);
                });
        }
        if (streamInfo.listEventType === 'removed') {
            dispatchRemoveStreamInfo(streamInfo.streamId);
        }
        if (streamInfo.listEventType === 'updated') {
            for (const previousStreamInfo of reduxStore.getState().conferencing.streamInfo) {
                if (streamInfo.streamId === previousStreamInfo.streamId) {
                    dispatchRemoveStreamInfo(previousStreamInfo.streamId);
                    dispatchAddStreamInfo(serializeStreamInfo(streamInfo));

                    // toggles the audio muted information in the conference user list
                    dispatchToggleAudioMuted({ userId: streamInfo.contact.userData.id, isAudioMuted: streamInfo.isAudioMuted });
                }
            }
        }
    }
};

const handleRemovalOfIncomingStream = stream => {
    const { callerId } = getURLParams();

    // caller audio stream
    if (stream.contact.userData.username === callerId) {
        if (reduxStore.getState().application.audioStreamIsActive) {
            addNotificationAndShowDispatch('info.aud_lst', 'info', DISPLAY_ONLY_IN_SESSION);
        }

        deactivateAudioStreamDispatcherDispatch();
    }

    stream.removeFromDiv('incomingStreamToDispatcher', 'stream-media-' + stream.streamId);
};

// AUDIO

const handleIncomingAudio = () => {
    if (!reduxStore.getState().application.audioIsMuted) {
        readdAllStreamsToContainerDispatcher();
    }
};

// ADD AND REMOVE STREAMS TO CONTAINER DISPATCHER

export const readdAllStreamsToContainerDispatcher = () => {
    removeAllStreamsFromContainerDispatcher();
    addAllStreamsToContainerDispatcher();
};

export const addAllStreamsToContainerDispatcher = () => {
    if (reduxStore.getState().streamSlice.callerAudioStream) {
        // fetch stream object from complex object storage
        const stream = getComplexObject(reduxStore.getState().streamSlice.callerAudioStream.streamId);
        if (stream) {
            stream.addInDiv('incomingStreamToDispatcher', 'stream-media-' + stream.streamId, {}, false);
        }
    }
    if (reduxStore.getState().streamSlice.conferenceUserStreams.length !== 0) {
        reduxStore.getState().streamSlice.conferenceUserStreams.forEach(reduxStream => {
            const stream = getComplexObject(reduxStream.streamId);
            if (stream) {
                stream.addInDiv('incomingStreamToDispatcher', 'stream-media-' + stream.streamId, {}, false);
            }
        });
    }
};

export const removeAllStreamsFromContainerDispatcher = () => {
    if (reduxStore.getState().streamSlice.callerAudioStream) {
        // fetch stream object from complex object storage
        const stream = getComplexObject(reduxStore.getState().streamSlice.callerAudioStream.streamId);
        if (stream) {
            stream.removeFromDiv('incomingStreamToDispatcher', 'stream-media-' + stream.streamId, {}, false);
        }
    }
    if (reduxStore.getState().streamSlice.conferenceUserStreams.length !== 0) {
        reduxStore.getState().streamSlice.conferenceUserStreams.forEach(reduxStream => {
            const stream = getComplexObject(reduxStream.streamId);
            if (stream) {
                stream.removeFromDiv('incomingStreamToDispatcher', 'stream-media-' + stream.streamId, {}, false);
            }
        });
    }
};

// CONTACT JOINED

const handleContactJoinedDispatcher = contactInfo => {
    const { callerId } = getURLParams();

    if (contactInfo.userData.username !== callerId && reduxStore.getState().invitedUsers.length < MAX_ALLOWED_CONFERENCE_USERS) {
        sendSessionData();
        sendApplicationData();
        sendSessionHandlingData();
    }
};

// CONTACT LEFT

const handleContactLeftDispatcher = contactInfo => {
    const { callerId } = getURLParams();
    // if current call leaves, set connection has been lost
    if (contactInfo.userData.username === callerId && contactInfo.userData.id === reduxStore.getState().sessionHandlingSlice.activeDeviceId) {
        connectionLostDispatch();
        return;
    }

    // if invited user leaves, remove from list
    if (contactInfo.userData.username !== callerId) {
        if (reduxStore.getState().invitedUsers.length > 0) {
            reduxStore.getState().invitedUsers.map(user => {
                if (user.userData.id === contactInfo.userData.id) {
                    const serializedContactInfo = serializeContact(contactInfo);
                    dispatchRemoveInvitedUser(serializedContactInfo.contact);
                }
                return null;
            });
        }
    }
};

const handleStreamRecordingStarted = recordingInfo => {
    let status = 'started';
    dispatchAddSessionRecording({ recordingInfo, status });
    postNewRecording(recordingInfo.mediaId, recordingInfo.mediaURL, recordingInfo.recordedFileName);
};

const handleStreamRecordingStopped = recordingInfo => {
    let status = 'stopped';
    dispatchAddSessionRecording({ recordingInfo, status });
};

const handleStreamRecordingIsAvailable = recordingInfo => {
    let status = 'available';
    dispatchAddSessionRecording({ recordingInfo, status });
    fetchVideoAndThumbnailAndDispatch(recordingInfo);
};